import { ref, h } from 'vue'
import { RouterLink } from 'vue-router'
import { NIcon } from 'naive-ui'
import { HomeOutline as HomeIcon } from '@vicons/ionicons5'
// import { UserRegular } from '@vicons/fa'
import {
  AdminPanelSettingsOutlined,
  ModelTrainingSharp,
  GolfCourseFilled,
  PermDataSettingOutlined,
  SupervisedUserCircleOutlined,
  BookOutlined,
  BookmarkAddedOutlined,
  FeaturedVideoOutlined,
  AltRouteFilled,
  PriceCheckRound,
  SupervisorAccountTwotone,
  // AddLinkFilled,
  // ArticleRound,
  ArticleOutlined,
  // CommentOutlined
  WebhookFilled
  // QuestionAnswerOutlined
} from '@vicons/material'

const renderIcon = (icon) => {
  return () => h(NIcon, null, { default: () => h(icon) })
}
export const menuOptions = ref([
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'welcome'
          }
        },
        { default: () => '首页' }
      ),
    key: 'go-back-home',
    icon: renderIcon(HomeIcon)
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'price'
          }
        },
        { default: () => '计划' }
      ),
    key: 'price-detail',
    icon: renderIcon(PriceCheckRound)
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'plan-index'
          }
        },
        { default: () => '目标' }
      ),
    key: 'plan-index',
    icon: renderIcon(AdminPanelSettingsOutlined)
  },
  {
    label: '练习',
    key: 'word-cards',
    icon: renderIcon(GolfCourseFilled),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-word-card'
              }
            },
            { default: () => '单词卡片' }
          ),
        key: 'user-word-card',
        icon: renderIcon(BookmarkAddedOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'word-hand-speed'
              }
            },
            { default: () => '单词手速' }
          ),
        key: 'word-hand-speed',
        icon: renderIcon(AltRouteFilled)
      }
    ]
  },
  {
    label: '词库',
    key: 'words-lib',
    icon: renderIcon(PermDataSettingOutlined),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'my-word'
              }
            },
            { default: () => '我的词库' }
          ),
        key: 'my-word',
        icon: renderIcon(BookOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'official-word'
              }
            },
            { default: () => '词库资源' }
          ),
        key: 'official-word',
        icon: renderIcon(SupervisorAccountTwotone)
      }
    ]
  },
  {
    label: '测评',
    key: 'train',
    icon: renderIcon(ModelTrainingSharp),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'train-word'
              }
            },
            { default: () => '单词测评' }
          ),
        key: 'train-word',
        icon: renderIcon(FeaturedVideoOutlined)
      }
    ]
  },
  {
    label: '精选',
    key: 'Featured Articles"',
    icon: renderIcon(ArticleOutlined),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'development'
              }
            },
            { default: () => '开发圈' }
          ),
        key: 'delelopment-circle',
        icon: renderIcon(WebhookFilled)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'english'
              }
            },
            { default: () => '英语圈' }
          ),
        key: 'english-circle',
        icon: renderIcon(SupervisedUserCircleOutlined)
      }
      // {
      //   label: () =>
      //     h(
      //       RouterLink,
      //       {
      //         to: {
      //           name: 'blockchain-web3'
      //         }
      //       },
      //       { default: () => '区块链落地' }
      //     ),
      //   key: 'web3-list',
      //   icon: renderIcon(WebhookFilled)
      // },
      // {
      //   label: () =>
      //     h(
      //       RouterLink,
      //       {
      //         to: {
      //           name: 'ai-app'
      //         }
      //       },
      //       { default: () => 'AI应用' }
      //     ),
      //   key: 'ai-list',
      //   icon: renderIcon(AddLinkFilled)
      // },
      // {
      //   label: () =>
      //     h(
      //       RouterLink,
      //       {
      //         to: {
      //           name: 'remote-work'
      //         }
      //       },
      //       { default: () => '独立开发' }
      //     ),
      //   key: 'remote-list',
      //   icon: renderIcon(SupervisedUserCircleOutlined)
      // }
    ]
  }
])
