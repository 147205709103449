import moment from 'moment'
const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  isExpired: (state) => {
    const currentTime = Date.now()
    // 没有找到超级VIP的信息，说明没有超级VIP
    const user_expiration = state.user.userInfo.expiration
    if (user_expiration) {
      const expiration = moment(user_expiration).valueOf()
      return currentTime > expiration
    }
    // 已经过期 返回false，没有过期
    return true
  },
  // 是否有权限
  hasPermissions: (state) => {
    const currentTime = Date.now()
    const expiration = moment(state.user.userInfo.expiration).valueOf()
    const isExpired = currentTime >= expiration
    if (state.user.userInfo.version !== '2') {
      return false
    }
    if (isExpired) {
      return false
    }
    return true
  }
}
export default getters
