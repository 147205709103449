const NAMESPACE = 'bokeen-user-v1'
const TOKEN = 'token'
// 用户信息
const USER_INFO = 'user_info'
// 本地词典
const LOCAL_DICT = 'local_dict'
// const APP_URL = 'http://localhost:3001'
const SPEED_CATE_INDEX = 'speed_cate_index'
// api到url
const APP_URL = 'https://api.bokeen.nodeing.com'
// 单词测评答案数据
const WORD_ANSWER_DATA = 'user_answer_data'
// 每个用户单词测评数据
const USER_EVALUATION_DATA = 'user_evaluation_data'
// 每个用户测评关卡数据
const USER_LEVEL_DATA = 'user_level_data'
// 当前正在阅读文章路由
const ATICLE_ROUTE = 'article_route'

// 访问音标的路径
const PONETIC_URL = 'https://dict.youdao.com/dictvoice'

const USER_SETTING = 'user_setting'

export {
  NAMESPACE,
  TOKEN,
  USER_INFO,
  LOCAL_DICT,
  APP_URL,
  WORD_ANSWER_DATA,
  SPEED_CATE_INDEX,
  USER_EVALUATION_DATA,
  USER_LEVEL_DATA,
  ATICLE_ROUTE,
  PONETIC_URL,
  USER_SETTING
}
