import layout from '@/layout'
export default {
  path: '/price',
  component: layout,
  name: 'price',
  meta: {
    title: '价格'
  },
  redirect: '/price/detail',
  children: [
    {
      path: '/price/detail',
      component: () => import('@/views/price/index'),
      name: 'price-detail',
      meta: {
        title: '价格与功能'
      }
    },
    {
      path: '/price/pay',
      component: () => import('@/views/price/pay.vue'),
      name: 'price-pay',
      meta: {
        title: '订阅支付'
      }
    }
  ]
}
