import layout from '@/layout'
import NotFound from '@/views/error-page/404'

export const publicRoutes = [
  {
    path: '/auth',
    component: () => import('@/views/login/index')
  },
  {
    path: '/index',
    component: () => import('@/views/index/index')
  },
  {
    path: '/help',
    component: () => import('@/views/index/help')
  },
  {
    path: '/',
    component: layout,
    redirect: '/index',
    name: 'index',
    meta: {
      title: '欢迎',
      icon: 'index'
    },
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        component: () => import('@/views/welcome/index'),
        meta: {
          title: '数据大盘',
          icon: 'home'
        }
      },
      {
        path: '/404',
        name: '404页面',
        component: () => import('@/views/error-page/404')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: NotFound
  }
]
