import layout from '@/layout'
export default {
  path: '/word',
  component: layout,
  name: 'word',
  meta: {
    title: '单词'
  },
  children: [
    {
      path: '/user-word',
      component: () => import('@/views/word/user/index'),
      name: 'my-word',
      meta: {
        title: '我的词库'
      }
    },
    {
      path: '/user-word/official',
      component: () => import('@/views/word/official/index'),
      name: 'official-word',
      meta: {
        title: '官方词库'
      }
    },
    {
      path: '/user-word/official/detail/:id',
      component: () => import('@/views/word/official/detailArticle'),
      name: 'official-word-detail',
      meta: {
        title: '官方词库详情'
      }
    },
    {
      path: '/user-word/category',
      component: () => import('@/views/word/user/category'),
      name: 'my-word-category',
      meta: {
        title: '单词分类'
      }
    },
    {
      path: '/user-word/card',
      component: () => import('@/views/word/card/index'),
      name: 'user-word-card',
      meta: {
        title: '单词卡片'
      }
    },
    {
      path: '/user-word/card/official/:id',
      component: () => import('@/views/word/card/official'),
      name: 'user-word-card-official',
      meta: {
        title: '官方单词卡片'
      }
    },
    {
      path: '/user-word/card/user/:id',
      component: () => import('@/views/word/card/user'),
      name: 'user-word-card-user',
      meta: {
        title: '用户单词卡片'
      }
    },
    {
      path: '/user-word/card/develop/:id',
      component: () => import('@/views/word/card/develop'),
      name: 'user-word-card-develop',
      meta: {
        title: '程序员专属'
      }
    },
    {
      path: '/user-word/speed',
      component: () => import('@/views/word/speed/index'),
      name: 'word-hand-speed',
      meta: {
        title: '单词手速'
      }
    },
    {
      path: '/user-word/speed/user/:id',
      component: () => import('@/views/word/speed/user'),
      name: 'word-hand-speed-user',
      meta: {
        title: '用户单词'
      }
    },
    {
      path: '/user-word/speed/develop/:id',
      component: () => import('@/views/word/speed/develop'),
      name: 'word-hand-speed-develop',
      meta: {
        title: '程序员专属'
      }
    },
    {
      path: '/user-word/speed/official/:id',
      component: () => import('@/views/word/speed/official'),
      name: 'word-hand-speed-official',
      meta: {
        title: '官方单词'
      }
    }
  ]
}
