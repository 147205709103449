import layout from '@/layout'
export default {
  path: '/train',
  component: layout,
  name: 'train',
  redirect: '/train/world/list',
  meta: {
    title: '测评'
  },
  children: [
    {
      path: '/train/world/list',
      component: () => import('@/views/train/index'),
      name: 'train-word',
      meta: {
        title: '单词测评'
      }
    },
    {
      path: '/train/world/detail/:id',
      component: () => import('@/views/train/detail'),
      name: 'train-word-detail',
      meta: {
        title: '测评详情'
      }
    },
    {
      path: '/train/world/introduce',
      component: () => import('@/views/train/introduce'),
      name: 'train-word-introduce',
      meta: {
        title: '付费介绍'
      }
    }
  ]
}
