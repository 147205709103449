import CryptoJS from 'crypto-js'
import $ from 'jquery'
import { LOCAL_DICT, APP_URL, USER_SETTING, PONETIC_URL } from '@/config'
import storage from './storage'

export const obj2url = (baseurl, data) => {
  let url
  if (data) {
    url = baseurl + '?'
    for (let k in data) {
      url += `${k}=${data[k]}&`
    }
    url = url.slice(0, -1)
  } else {
    url = baseurl
  }
  return url
}

export const totalDuration = (time) => {
  if (time < 0) return '0'
  let hours = String(Math.floor(time / 3600))
  let minutes = String(Math.floor((time % 3600) / 60))
  let seconds = String(Math.floor(time % 60))

  if (Number(hours) < 10) {
    hours = '0' + hours
  }
  if (Number(minutes) < 10) {
    minutes = '0' + minutes
  }
  if (Number(seconds) < 10) {
    seconds = '0' + seconds
  }

  return hours + ':' + minutes + ':' + seconds
}
// 洗牌算法，随机打乱数组顺序
export function shuffleArray(array) {
  const shuffledArray = [...array] // 创建一个副本以避免修改原始数组

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1)) // 生成一个随机索引
    // 交换当前元素与随机索引处的元素
    ;[shuffledArray[i], shuffledArray[randomIndex]] = [
      shuffledArray[randomIndex],
      shuffledArray[i]
    ]
  }

  return shuffledArray
}
const truncate = (q) => {
  const len = q.length
  if (len <= 20) return q
  return q.substring(0, 10) + len + q.substring(len - 10, len)
}
// 查询单词
export const translateWord = (word) => {
  return new Promise((resolve, reject) => {
    const APP_ID = process.env.VUE_APP_APP_ID
    const APP_KEY = process.env.VUE_APP_APP_KEY
    try {
      const salt = new Date().getTime()
      const curtime = Math.round(new Date().getTime() / 1000)
      const from = 'en' // 自动检测语言
      const to = 'zh-CHS'
      const str1 = APP_ID + truncate(word) + salt + curtime + APP_KEY
      const sign = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex)
      $.ajax({
        url: 'https://openapi.youdao.com/api',
        type: 'post',
        dataType: 'jsonp',
        data: {
          q: word,
          appKey: APP_ID,
          salt: salt,
          from: from,
          to: to,
          sign: sign,
          signType: 'v3',
          curtime: curtime,
          voice: '1',
          ext: 'mp3'
        },
        success: function (data) {
          data.speakUrl = data.speakUrl + '&voiceName=youxiaoguan'
          resolve(data)
        }
      })
    } catch (error) {
      console.error(error)
      reject(error)
    }
  })
}
let audio = null
let isPlaying = false
export const playVoice = (src) => {
  if (!audio) {
    audio = new Audio(src)
    // 播放完毕后，手动释放音频对象
    audio.onended = function () {
      isPlaying = false
      audio.pause()
      audio.src = ''
      audio.currentTime = 0
      audio.onended = null // 清除事件监听
      audio = null // 将对象设置为 null
    }
    if (!isPlaying) {
      // 只有当没有正在播放时才播放
      audio.play()
      isPlaying = true // 更新标志
    }
  }
}

export const getWordDetail = async (word) => {
  // 先查询本地词典，本地词典如果有，就拿本地，本地没有就去接口拿
  let local_dict = storage.getItem(LOCAL_DICT)
  if (!local_dict) {
    local_dict = {}
    const result = await translateWord(word)
    local_dict[word] = result
    storage.setItem(LOCAL_DICT, local_dict)
  } else {
    // 判断单词是否存在
    if (word in local_dict) {
      return local_dict[word]
    } else {
      const result = await translateWord(word)
      local_dict[word] = result
      storage.setItem(LOCAL_DICT, local_dict)
      return result
    }
  }
}

export const enterFullscreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  }
}

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}

export const getAudioUrl = (word) => {
  if (!word) {
    return
  }
  const settings = storage.getItem(USER_SETTING) || {
    phonetic: '1',
    card_level_count: '20',
    speed_level_count: '20'
  }
  if (settings.phonetic === '3') {
    return APP_URL + word.word_detail.audio_url + `?v=${Date.now()}`
  }
  return PONETIC_URL + `?audio=${word.name}&type=${settings.phonetic}`
}

export const formatTime = (time) => {
  // 创建日期对象
  const date = new Date(time)
  // 获取年、月、日
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // 月份是从 0 开始计数的，需要加 1，并且补零
  const day = date.getDate().toString().padStart(2, '0') // 日需要补零

  // 格式化成 "YYYY-MM-DD" 形式
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const formatTimeHMS = (time) => {
  // 创建日期对象
  const date = new Date(time)
  // 获取年、月、日、时、分、秒
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // 月份是从 0 开始计数的，需要加 1，并且补零
  const day = date.getDate().toString().padStart(2, '0') // 日需要补零
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')

  // 格式化成 "YYYY-MM-DD HH:MM:SS" 形式
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

  return formattedDate
}

export const getDaysRemainingUntilFutureTime = (futureTimeString) => {
  // 将未来时间字符串解析为时间戳
  const futureTimestamp = Date.parse(futureTimeString)

  // 获取当前日期的时间戳
  const currentTimestamp = Date.now()

  // 计算天数差异
  const daysRemaining = Math.ceil(
    (futureTimestamp - currentTimestamp) / (1000 * 60 * 60 * 24)
  )

  return daysRemaining
}
