import { getCurrentUser } from '@/api/user'
import { login, registerByPhone } from '@/api/login'
import storage from '@/utils/storage'
import { TOKEN, USER_INFO } from '@/config'
import router from '../router'
export default {
  namespaced: true,
  state: () => ({
    token: storage.getItem(TOKEN) || '',
    userInfo: {}
    // selectedKey: storage.getItem(SELECTED_KEY) || '/user/info'
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      storage.setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      storage.setItem(USER_INFO, userInfo)
    }
    // setSelectedKey(state, key) {
    //   state.selectedKey = key
    //   storage.setItem(SELECTED_KEY, key)
    // }
  },
  actions: {
    login({ commit }, userData) {
      return new Promise((resolve, reject) => {
        login(userData)
          .then((data) => {
            resolve(data)
            commit('setToken', data.data.token)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    logout({ commit }) {
      commit('setToken', '')
      commit('setUserInfo', {})
      storage.clearAll()
      router.push('/index')
    },
    register({ commit }, registerData) {
      return new Promise((resolve, reject) => {
        registerByPhone(registerData)
          .then((data) => {
            resolve(data)
            commit('setToken', data.data.token)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getUserInfo({ commit }) {
      try {
        const { data } = await getCurrentUser()
        commit('setUserInfo', data.data)
      } catch (err) {
        /* eslint-disable */
        console.error(err)
      }
    }
  }
}
