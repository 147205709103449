import layout from '@/layout'
export default {
  path: '/users',
  component: layout,
  name: 'user',
  meta: {
    title: '用户'
  },
  redirect: '/user/info',
  children: [
    {
      path: '/user/list',
      component: () => import('@/views/user/index'),
      name: 'user-list',
      meta: {
        title: '普通用户'
      }
    },
    {
      path: '/user/info',
      component: () => import('@/views/user/index.vue'),
      name: 'user-detail',
      meta: {
        title: '个人中心'
      }
    }
  ]
}
