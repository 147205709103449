import layout from '@/layout'
export default {
  path: '/plan',
  component: layout,
  name: 'plan',
  meta: {
    title: '目标'
  },
  children: [
    {
      path: '/plan/index',
      component: () => import('@/views/plan/index'),
      name: 'plan-index',
      meta: {
        title: '目标管理'
      }
    }
  ]
}
