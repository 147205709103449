import layout from '@/layout'
export default {
  path: '/featured-article',
  component: layout,
  name: 'featured-article',
  meta: {
    title: '文章精选'
  },
  children: [
    {
      path: '/featured-article/development',
      component: () => import('@/views/circle/development'),
      name: 'development',
      meta: {
        title: '开发圈'
      }
    },
    {
      path: '/featured-article/english',
      component: () => import('@/views/circle/english'),
      name: 'english',
      meta: {
        title: '英语圈'
      }
    },
    {
      path: '/featured-article/detail/:id',
      component: () => import('@/views/circle/detailArticle'),
      name: 'article-detail',
      meta: {
        title: '阅读文章'
      }
    }
  ]
}
