import request from '@/utils/request'
import { obj2url } from '@/utils/tools'

// 获取用户详情
export const getUserDetail = (data) => {
  return request({
    url: '/users/' + data.id,
    method: 'GET'
  })
}

// 获取当前user
export const getCurrentUser = () => {
  return request({
    url: '/users/userinfo',
    method: 'GET'
  })
}

// 获取当前user的邀请码
export const generateInviteCode = () => {
  return request({
    url: '/users/invite-code',
    method: 'PUT'
  })
}

// 获取当前用户的邀请用户列表

export const inviteUserList = (data) => {
  const url = obj2url('/users/invite-user', data)
  return request({
    url: url,
    method: 'GET'
  })
}

export const withdrawalList = (data) => {
  const url = obj2url('/users/withdrawal', data)
  return request({
    url: url,
    method: 'GET'
  })
}

export const applyWithdrawal = (data) => {
  return request({
    url: '/users/withdrawal',
    method: 'POST',
    data
  })
}
